



















import { defineComponent, computed } from '@vue/composition-api';
import { FCCard } from '@fc/angie-ui';
import { IArticleCardAttrs } from './types';

export default defineComponent({
  name: 'ArticleCard',
  components: {
    FCCard,
  },
  inheritAttrs: false,
  setup(_, context) {
    const {
      codeId: trackingData,
      title,
      subText,
      publishedDate,
      articleLink,
    } = context.attrs as IArticleCardAttrs;

    const formattedDate = new Date(publishedDate?.replace(/-/g, '/')).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });

    return {
      articleUrl: computed(() => articleLink?.url),
      trackingData,
      title,
      subText,
      formattedDate,
      articleLink,
      isValidDate: computed(() => formattedDate !== 'Invalid Date'),
    };
  },
});
